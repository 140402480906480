@font-face {
  font-family: Handwriting;
  src: url("VAG-HandWritten.otf");
}
@font-face {
  font-family: Ubuntu;
  src: url("../static/fonts/Ubuntu-R.ttf");
}
@import url("https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap");
* {
  margin: 0;
  padding: 0;
}

html {
  height: 100vh;
  width: 100vw;
}
body {
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  background-color: #181818;
  visibility: hidden;
  top: 50%;
  left: 50%;
}

.webgl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: calc(var(--vh, 1vh) * 100);
  min-width: calc(var(--vw, 1vw) * 100);
}

/*****************  SIDENAV  *****************/

.sideNav {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1000px;
  width: 100px;
  z-index: 10;
  transition: 0.3s;
  padding-top: 30px;

  flex-wrap: nowrap;
}

.sideNavButton {
  width: 50px;
  height: 50px;
  background-image: url("../static/textures/ui/button_a.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sideNavIcon {
  width: 24px;
  height: 24px;
}

.sideNav :hover {
  filter: brightness(120%);
}

/*****************  BOTTOM NAV  *****************/

.bottomNav {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 140px;
  width: 100%;
  z-index: 10;
  transition: 0.3s;
  pointer-events: none;
}

.bottomNavButton {
  width: 100px;
  height: 100px;

  background-image: url("../static/textures/ui/button_b.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  pointer-events: all;
}

.bottomNavIcon {
  width: 50px;
  height: 50px;
  margin-left: 25px;
  margin-top: 25px;
}

.bottomLightButton {
  width: 100px;
  height: 100px;
  pointer-events: all;
}

.bottomBackButton {
  width: 60px;
  height: 60px;
  background-image: url("../static/textures/ui/button_b.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
}

.bottomBackIcon {
  width: 30px;
  height: 30px;
}

.bottomNav :hover {
  filter: brightness(120%);
}

.bottomSubNavButton {
  width: 100px;
  height: 100px;
  background-image: url("../static/textures/ui/button_c.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  pointer-events: all;
}

.bottomSubNavIcon {
  width: 70px;
  height: 70px;
  margin-left: 15px;
  margin-top: 15px;
}

/*****************  CARD NAV  *****************/

.bottomCardNav {
  position: absolute;
  bottom: 0%;
  display: flex;
  left: 50%;
  transform: translate(-50%, 0%);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;
  z-index: 10;
  transition: 0.3s;
  /* background-color: chartreuse; */
  pointer-events: none;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.cardScrollArea {
  width: 1060px;
  height: 280px;
  /* background-color: blueviolet; */
  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  pointer-events: none;
}
.bottomCardButton {
  width: 9vw;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
  /* background-color: brown; */
}

.bottomCardIcon {
  width: 160px;
  height: 263px;
}
.bottomCardButton :hover {
  filter: brightness(120%);
}

.cardScrollButton {
  width: 80px;
  height: 80px;
  margin: 20px;
  /* background-color: chocolate; */
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
}

.cardScrollIcon {
  width: 80px;
  height: 80px;
}
/*****************  RIGHT SIDE NAV  *****************/

.assetWindow {
  position: absolute;
  z-index: 9;
  text-align: center;
  background: url("../static/textures/ui/window_frame_a_700.webp");
  width: 350px;
  height: 700px;
  background-repeat: no-repeat;
  background-size: auto;
  top: 5%;
  right: 3%;
  transform: scale(0.8, 0.8);
  transform: translate(0%, 10%);
  background-color: #4caf50;
}

.assetWindowBody {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: 100px;
  margin-top: 20px;
  padding: 25px;
}

.grid-item {
  width: 80px;
  height: 80px;
  background-image: url("../static/textures/ui/button_c.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.grid-item-image {
  width: 76px;
  height: 76px;
}
.assetWindowTitle {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-color: blueviolet;  */
  width: 350px;
}
.assetWindowBanner {
  position: absolute;
  width: 420px;
  height: 176px;
  /* background-color: aqua;  */
  z-index: 10;
  margin-top: 0px;
}
.assetScrollArea {
  height: 625px;
  margin-top: 60px;
}

.assetWindowBackButton {
  position: absolute;
  left: 90%;
  top: 45%;
  width: 70px;
  height: 70px;
  background-image: url("../static/textures/ui/button_b.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
}

.assetWindowBackIcon {
  width: 30px;
  height: 30px;
  transform: rotate(180deg);
  margin-left: 5px;
}

.assetWindowBackButton :hover {
  filter: brightness(160%);
}

.grid-item :hover {
  filter: brightness(120%);
}
/*****************  DICE ROLLER  *****************/

.dicewrapper {
  position: absolute;
  top: 10%;
  left: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 650px;
}

.diceWindow {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 140px;
  width: 600px;
  transition: 0.3s;
  pointer-events: none;
  background-color: rgb(93, 64, 224);
  background-image: url("../static/textures/ui/dice_window.webp");
}

.diceMultiplierBar {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 98%;
  gap: 2px;
  margin-top: 4px;
  transition: 0.3s;
  pointer-events: none;
}
.diceMultiplierButton {
  margin-top: 2px;
  width: 47px;
  height: 47px;
  background-image: url("../static/textures/ui/button_d.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  color: rgb(226, 182, 116);
  cursor: pointer;
  transition: 0.3s;
}

.diceRowA {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 63px;
  margin-right: 360px;
  justify-content: center;
  height: 70px;
  width: 225px;

  transition: 0.3s;
  pointer-events: none;
}
.diceRowB {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 63px;
  margin-left: 360px;
  justify-content: center;
  height: 70px;
  width: 225px;

  transition: 0.3s;
  pointer-events: none;
}
.diceResult {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 63px;
  justify-content: center;
  height: 140px;
  width: 140px;
  transition: 0.3s;
  pointer-events: none;

  background-image: url("../static/textures/ui/diceresultb.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

#diceResultText {
  color: aliceblue;
  font-size: 45px;
}
.diceBanner {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 190px;
  justify-content: center;
  height: 95px;
  width: 405px;
  transition: 0.3s;
  background-image: url("../static/textures/ui/dicebanner.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  cursor: move;
  pointer-events: all;
}

.diceButton {
  height: 80px;
  width: 80px;
  transition: 0.3s;
  background-image: url("../static/textures/ui/button_b.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
}
.diceRowA :hover {
  filter: brightness(120%);
}
.diceRowB :hover {
  filter: brightness(120%);
}
.closeButton {
  height: 35px;
  width: 35px;
  transition: 0.3s;
  pointer-events: none;
  margin-bottom: 140px;
  margin-right: 600px;
  background-image: url("../static/textures/ui/close_button.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  z-index: 2;
  cursor: pointer;
}
.diceIcon {
  width: 40px;
  height: 40px;
}

.bottomSubNavButton.inactive {
  pointer-events: none;
}

/*****************  SETTINGS WINDOW  *****************/

.settingswrapper {
  position: absolute;
  top: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.settingsWindow {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 520px;
  width: 350px;

  transition: 0.3s;
  pointer-events: none;
  background-image: url("../static/textures/ui/settings_frame.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  /* background-color: blueviolet; */
}

.dropdownWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 225px;
  gap: 25px;
  margin-top: 75px;
  /* background-color: blue; */
  transition: 0.3s;
  pointer-events: none;
  z-index: 20;
}

.dropdownFrame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  height: 200px;
  width: 225px;
  gap: 10px;
  /* background-color: rgb(255, 8, 185); */
  transition: 0.3s;
  pointer-events: all;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  width: 170px;
  height: 50px;
  background-image: url("../static/textures/ui/dropdown_a.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #181818;
  min-width: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  cursor: pointer;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: rgb(216, 216, 216);
  padding: 12px 16px;
  text-align: center;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #252525;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  filter: brightness(120%);
}
.settingsCloseButton:hover {
  filter: brightness(120%);
}
.settingsCloseButton {
  position: absolute;
  height: 65px;
  width: 65px;
  margin-top: 490px;
  transition: 0.3s;
  pointer-events: none;
  background-image: url("../static/textures/ui/confirm_button.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  z-index: 2;
  cursor: pointer;
}

.clearmapwrapper {
  position: absolute;
  top: 40%;
  left: 40%;
  width: 356px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../static/textures/ui/clearmap_window.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.clearMapCloseButton {
  position: absolute;
  height: 65px;
  width: 65px;
  margin-top: 145px;
  margin-right: 70px;
  transition: 0.3s;
  pointer-events: none;
  background-image: url("../static/textures/ui/close_button.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  z-index: 2;
  cursor: pointer;
}
.clearMapConfirmButton {
  position: absolute;
  height: 65px;
  width: 65px;
  margin-top: 145px;
  margin-left: 70px;
  transition: 0.3s;
  pointer-events: none;
  background-image: url("../static/textures/ui/confirm_button.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  z-index: 2;
  cursor: pointer;
}

.clearMapConfirmButton:hover {
  filter: brightness(120%);
}

.clearMapCloseButton:hover {
  filter: brightness(120%);
}

.loadingScreen {
  position: absolute;
  width: 100vw;
  height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  z-index: 200;
  background-color: #181818;
  color: white;
  display: flex;
  align-items: center;
  justify-items: center;
}

.loadingText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
}

.loading-bar {
  position: absolute;
  top: 50%;
  width: 20%;
  height: 2px;
  left: 40%;
  background: #ffffff;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.5s;
}
.loading-bar.ended {
  transform: scaleX(0);
  transform-origin: 100% 0;
  transition: transform 1.5s ease-in-out;
}

#dropzoneWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
}

.dropzoneCloseButton {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: -35%;
  left: 50%;
  background-image: url("../static/textures/ui/button_b.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  transform: translate(-50%, -50%);
  padding: 20px;
  padding-right: 25px;
  margin-top: 90px;
}

.dropzoneCloseButton :hover {
  filter: brightness(120%);
}

.uploadButton {
  background-image: url("../static/textures/ui/button_b.webp");
  height: 120px;
  width: 120px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;

  cursor: pointer;

  align-items: center;
}
.uploadButton :hover {
  filter: brightness(120%);
}
.uploadButtonIcon {
  height: 55px;
  width: 55px;
  margin-top: 32px;
  margin-left: 32px;
}
.uploadButtonText {
  margin-top: 60px;
  color: #ffffff;
}

#uploadLabel {
  text-align: center;
}
/* <div id="uploadModelSelect"></div>
<div id="uploadMapSelect"></div> */
.dropzone {
  padding: 2em;
  display: flex;
  justify-content: center;
  width: 400px;
  height: 200px;
  background-image: url("../static/textures/ui/uploadWindow.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  position: absolute;
  z-index: 10323;
  margin: 0 auto;
  /* background-color: #4caf50; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  gap: 25px;
  visibility: hidden;
}
.uploadIcon {
  width: 100px;
  height: 100px;
}
/* .uploadClose {
  position: absolute;
  left: 0%;
  width: 35px;
  height: 35px;
  top: 0%;
  margin-top: -40px;
  margin-left: -50px;
} */
.list,
.info {
  text-align: left;
}

.dropzone input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

#hintText {
  position: absolute;
  top: 1%;
  margin-left: 100px;
  font-family: Handwriting;
  color: white;
  font-size: 26px;
  user-select: none;
  pointer-events: none;
  /* opacity: 0; */
  max-width: 340px;
}

.loader-mask {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
}

.loader-fill {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  transform-origin: 50% 100%;
}

.socialBar {
  position: absolute;
  bottom: 2%;
  left: 2%;
  width: 200px;
  height: 40px;
  display: flex;
  gap: 5px;
  z-index: 50;
}
.socialButton {
  width: 25px;
  height: 25px;
  opacity: 0.2;
}
.socialIcon {
  width: 25px;
  height: 25px;
}
.socialButton:hover {
  opacity: 0.7;
}

.donateButton {
  position: absolute;
  bottom: 2%;
  right: 1%;

  height: 40px;
  display: flex;
  gap: 4px;
  padding: 8px 10px;
  justify-content: end;
  align-items: center;
  font-weight: 600;
  font-family: Ubuntu;
  font-size: 12px;
  color: white;
  opacity: 0.7;
  cursor: pointer;
  background-color: deepskyblue;
  border-radius: 10px;
  z-index: 50;
}

.donateButton:hover {
  opacity: 1;
}

.heart-icon {
  width: 15px;
  height: 15px;
  background-image: url("../static/textures/ui/icon_heart.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
/*****************  TOOL TIPS  *****************/

/* Tooltip container */
.tooltip {
  position: relative;

  align-items: left;
}

.tooltipImage {
  visibility: hidden;
  width: 140px;
  height: 140px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  margin-left: 100px;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 2000;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltipImage {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltipImageBottom {
  visibility: visible;
  opacity: 1;
}
.tooltipImageBottom {
  visibility: hidden;
  width: 140px;
  height: 140px;
  position: absolute;
  z-index: 1;
  top: -50%;
  left: 50%;
  margin-bottom: 120px;
  transform: translate(-50%, -50%);
  z-index: 2000;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

#campaignList {
  position: absolute;
  width: 100%;
  height: 190px;
  top: 0%;
  display: flex;
  background-image: url("../static/textures/ui/campaing_bg.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  background-color: brown;
}

.campaignContainer {
  width: 100%;
  height: 150px;
  top: 0%;

  margin-left: 25px;
  margin-right: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  gap: 20px;

  align-items: center;
}

.campaignItem {
  width: 240px;
  height: 130px;
  color: white;
  text-shadow: 2px 2px 4px #000000;
  box-shadow: 2px 2px 4px #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: medium;
  opacity: 1;
  cursor: pointer;
  border-radius: 15px;
  background-image: url("../static/textures/ui/cards/landscapes/natureh.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 1000;
}
.campaignItem:hover {
  filter: brightness(120%);
}

#newCampaignItem {
  border-style: solid;
  border-color: #ffffff;
  border-radius: 15px;
  border-width: 2px;
  background-color: rgb(255, 255, 255, 0.3);
  background-image: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

#newCampaignIcon {
  width: 90px;
  height: 90px;
  position: absolute;
}
#newCampaignItem:hover {
  background-color: rgb(255, 255, 255, 0.5);
}
#campaignCloseButton {
  position: absolute;
  bottom: 0%;
  left: 50%;
  width: 50px;
  height: 50px;
  background-image: url("../static/textures/ui/button_b.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
  margin-bottom: -25px;
  transform: rotate(90deg);
  z-index: 50;
}

.campaignCloseIcon {
  position: relative;
  top: 0%;
  height: 20px;
  width: 20px;
}
.campaignItemButtons {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 40px;
  background-color: #a54caf;
}
.campaignItemTrash {
  height: 20px;
  width: 20px;
  background-color: #ffffff;
}

.campaignItemRename {
  height: 20px;
  width: 20px;
  background-color: #ffffff;
}

/*****************  MULTIPLAYER NAV  *****************/

#multiplayerSelectWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

#multiplayerCreateSelect {
  width: 300px;
  height: 92px;
  background-image: url("../static/textures/ui/create-room-button.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  cursor: pointer;
}
#multiplayerJoinSelect {
  width: 300px;
  height: 92px;
  background-image: url("../static/textures/ui/join-room-button.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  cursor: pointer;
}

#multiplayerJoinSelect:hover {
  filter: brightness(120%);
}
#multiplayerCreateSelect:hover {
  filter: brightness(120%);
}
.createRoomWrapper {
  position: absolute;
  top: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.createRoomWindow {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 520px;
  width: 350px;
  transition: 0.3s;
  pointer-events: none;
  background-image: url("../static/textures/ui/createRoom_frame.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.joinRoomWindow {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 520px;
  width: 350px;
  transition: 0.3s;
  pointer-events: none;
  background-image: url("../static/textures/ui/joinRoom_frame.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.createRoomCloseButton {
  height: 40px;
  width: 40px;
  transition: 0.3s;
  pointer-events: none;
  margin-bottom: 440px;
  margin-right: 300px;
  background-image: url("../static/textures/ui/close_button.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  z-index: 2;
  cursor: pointer;
}

.selectAvatarButton {
  position: absolute;

  left: 50%;
  margin-top: 130px;
  transform: translate(-50%, -50%);
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: all;
  background-image: url("../static/textures/ui/button_light_white.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.selectAvatarButton :hover {
  filter: brightness(120%);
}
.selectAvatarIcon {
  height: 130px;
  width: 130px;
  border-radius: 50%;
}

#usernameTitle {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  font-weight: bold;
  color: rgba(118, 0, 0, 0.7);
  text-shadow: rgba(0, 0, 0, 0.3);
  margin-top: 700px;
}

#roomIDTitle {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  font-weight: bold;
  color: rgba(118, 0, 0, 0.7);
  text-shadow: rgba(0, 0, 0, 0.3);
  margin-top: 860px;
}
#createRoomUsernameInput {
  -webkit-appearance: none;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 190px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: medium;
  pointer-events: all;
  cursor: pointer;
  font-family: "Quattrocento", sans-serif;
  text-align: center;
  border: 0;
  outline: 0;
  border-bottom: 2px solid rgba(118, 0, 0, 0.7);
  font-weight: bold;
}

#joinRoomUsernameInput {
  -webkit-appearance: none;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 190px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: medium;
  pointer-events: all;
  cursor: pointer;
  font-family: "Quattrocento", sans-serif;
  text-align: center;
  border: 0;
  outline: 0;
  border-bottom: 2px solid rgba(118, 0, 0, 0.7);
  font-weight: bold;
}

#joinRoomIDInput {
  -webkit-appearance: none;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 190px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: medium;
  pointer-events: all;
  cursor: pointer;
  font-family: "Quattrocento", sans-serif;
  text-align: center;
  border: 0;
  outline: 0;
  border-bottom: 2px solid rgba(118, 0, 0, 0.7);
  font-weight: bold;
}

#multiplayerJoinConfirmButton {
  margin-top: 520px;
}
#createRoomUsernameInput:focus {
  outline: none !important;
}

#avatarWindow {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 600px;
  background-image: url("../static/textures/ui/selectAvatarWindow.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  padding-top: 325px;
}

#avatarSelectScrollWindow {
  width: 450px;
  height: 425px;
  margin: auto;
  display: flex;
  overflow: scroll;
}

#avatarSelectGrid {
  display: flex;

  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}
.avatarWindowImage {
  width: 75px;
  height: 75px;
  background-color: aqua;
  background-image: url("../static/textures/ui/button_light_white.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  flex-shrink: 0;
  cursor: pointer;
}

#avatarWindowCloseButton {
  position: absolute;
}
.avatarWindowImage:hover {
  filter: brightness(120%);
}

.multiplayer-room-info {
  position: absolute;
  bottom: 9%;
  left: 3%;
  width: 200px;
  height: 40px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  color: white;
  opacity: 0.2;
  cursor: pointer;
  z-index: 10;
}

.multiplayer-room-info:hover {
  opacity: 0.7;
}

.chat-box {
  position: absolute;
  bottom: 15%;
  left: 3%;
  width: 300px;
  height: 80px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  color: white;
  cursor: pointer;
  z-index: -10;
  -webkit-mask-image: linear-gradient(to top, black 0%, transparent 100%);
  mask-image: linear-gradient(to top, black 0%, transparent 100%);

  justify-content: flex-end;
}

.avatar-bar {
  position: absolute;
  top: 2%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 400px;
  height: 80px;
  display: flex;
  gap: 15px;
  color: white;
  z-index: 10;
  justify-content: center;
  align-items: center;
}

.avatar-item {
  width: 50px;
  height: 50px;
  background-image: url("../static/textures/ui/cards/avatars/Elf_01.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: blue;
  -webkit-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.63);
  -moz-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.63);
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.63);
  pointer-events: all;
}

.avatar-item:hover .avatar-item-name {
  display: block;
}

.avatar-item:hover {
  filter: brightness(150%);
}

.avatar-item-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 110%);
  display: none;
  text-align: center;
}

/*****************  DONATE NAV  *****************/

.donate-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(var(--vw, 1vw) * 100);
  background-color: #ffffff;
  z-index: 100;
  height: 100%;
  display: block;
  overflow: auto;
}

.donate-back-button {
  position: absolute;
  top: 3%;
  left: 3%;
  height: 80px;
  font-family: ubuntu;
  color: cornflowerblue;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 100;
}

.icon-arrow-blue {
  width: 20px;
  height: 20px;

  cursor: pointer;
}

.donate-page-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
}
.donate-title {
  font-size: 4rem;
  font-family: ubuntu;
  font-weight: 700;
  color: cornflowerblue;
  margin-top: 150px;
  text-align: center;
  background: #5a8bea;
  background: linear-gradient(to right, #5a8bea 0%, #00cbea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.donate-subtitle {
  font-size: 24px;
  font-family: ubuntu;
  font-weight: 100;
  color: black;
  margin-top: 50px;
  text-align: center;
  padding: 0% 20%;
  background: #2a2a2a;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.donate-news-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  background-color: #ffffff;
  padding: 0% 20%;
  width: 60%;
}

.donate-row-title {
  font-size: 2.5rem;
  width: 40%;
  height: 100%;
  font-family: ubuntu;
  font-weight: 600;
  color: black;
  text-align: center;
  background: #5a8bea;
  background: linear-gradient(to right, #5a8bea 0%, #00cbea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.donate-row-content {
  font-size: 1rem;
  font-family: ubuntu;
  font-weight: 100;
  color: black;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding-top: 20px;
  width: 60%;
  height: 100%;
  line-height: 1.5rem;
}

#paypal {
  width: 50%;
  margin-top: -25px;
  margin-left: 25px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;
}
#patreon {
  margin-top: 0px;
  margin-left: 25px;
  width: 80%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;
}
.donate-footer {
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 100;
  padding: 100px;
}

.donate-progress-title {
  margin-top: -25px;
  font-size: 1rem;
  font-family: ubuntu;
  font-weight: 600;
  color: black;
  text-align: center;
  background: #5a8bea;
  background: linear-gradient(to right, #5a8bea 0%, #00cbea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.meter {
  box-sizing: content-box;
  height: 50px; /* Can be anything */
  width: 40%; /* Can be anything */
  position: relative;
  margin: 20px 0 20px 0; /* Just for demo spacing */
  background: #555;
  border-radius: 25px;
  padding: 10px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}
.meter > span {
  display: block;
  height: 20px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgb(43, 194, 83);
  background-image: linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}
.meter > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

.animate > span:after {
  display: none;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.orange > span {
  background-image: linear-gradient(#f1a165, #f36d0a);
}

.red > span {
  background-image: linear-gradient(#5a8bea, #00cbea);
}

.nostripes > span > span,
.nostripes > span::after {
  background-image: none;
}

.error-message {
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

/*****************  QUESTFORGE AD  *****************/

#questforge-ad {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  border-radius: 15px;
  border: 2px solid #ffffff;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
  background: rgb(40, 39, 40);
  background: linear-gradient(
    180deg,
    rgba(40, 39, 40, 1) 0%,
    rgb(255, 0, 136) 100%
  );
  box-shadow: 10px 10px rgba(0, 0, 0, 1);
}

#questforge-ad-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#questforge-ad-content iframe {
  margin: auto;
  pointer-events: none;
}

#questforge-ad-title {
  font-family: Ubuntu, sans-serif;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 25px;
  color: white;
  text-shadow: 3px 3px rgba(0, 0, 0, 1);
}

#questforge-ad-subtitle {
  font-family: Ubuntu, sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: white;
  margin: 25px 0px;
  text-align: center;
  line-height: 1.8rem;
}

#questforge-ad-button {
  height: 30px;
  border: none;
  padding: 15px 50px;
  font-family: Ubuntu, sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  margin-left: 10px;
  box-shadow: 5px 5px rgba(0, 0, 0, 1);
  background-color: rgb(79, 70, 229);
  color: white;
  text-shadow: 3px 3px rgba(0, 0, 0, 1);
}

#questforge-ad-button:hover {
  cursor: pointer;
  filter: brightness(1.2);
}

#questforge-ad-close {
  position: absolute;
  top: 10px;
  right: 4px;
  width: 30px;
  height: 30px;
  color: white;
  font-size: 1.8rem;
  font-family: Ubuntu, sans-serif;
  /* font-weight: 600; */
  text-shadow: 3px 3px rgba(0, 0, 0, 1);
  cursor: pointer;
}
